import $ from 'jquery'
import Screen from './modules/screen'
import setMetaViewport from './modules/setMetaViewport'
import pageTop from './modules/pagetop'
import logoAnimation from './modules/logoAnimation'
import headerFixed from './modules/headerFixed'
import headerMenu from './modules/headerMenu'
import headerNav from './modules/headerNav'
import mainVisual from './modules/mainVisual'
import animateTopServicePc from './modules/animateTopServicePc'
import animateTopServiceSp from './modules/animateTopServiceSp'
import customSelectbox from './modules/customSelectbox'
import bottomNavBarSp from './modules/bottomNavBarSp'
import navSidePanel from './modules/navSidePanel'
import navPhilosophy from './modules/navPhilosophy'
import tabContent from './modules/tabContent'
import initMatchHeight from './modules/matchHeight'
import imageSlider from './modules/imageSlider'
import linkToHash from './modules/linkToHash'
import anchorLinks from './modules/anchorLinks'
import contactForm from './modules/contactForm'
import historyPage from './modules/historyPage'
import clientsPage from './modules/clientsPage'
import sessionCheck from './modules/sessionCheck'
import tagChange from './modules/tagChange'
import languageLink from './modules/languageLink'
import googleMap from './modules/maps'
import reveal from './modules/scrollReveal'
import scrollFadeIn from './modules/scrollFadeIn'

/* ----------------------------------------------------------
  init
---------------------------------------------------------- */
setMetaViewport()
logoAnimation()
headerFixed()
headerMenu()
headerNav()
pageTop()
anchorLinks()
linkToHash()
mainVisual()
animateTopServicePc()
animateTopServiceSp()
customSelectbox()
bottomNavBarSp()
navPhilosophy()
tabContent()
navSidePanel()
initMatchHeight()
imageSlider()
contactForm()
historyPage()
clientsPage()
sessionCheck()
tagChange()
languageLink()
googleMap()
reveal()
scrollFadeIn()

/* ----------------------------------------------------------
  Screen
---------------------------------------------------------- */
const screen = new Screen()
screen.isTouchDevice()
$(window).on('resize', screen.size())
