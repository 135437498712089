export default {
  v: '5.7.4',
  fr: 25,
  ip: 0,
  op: 90,
  w: 273,
  h: 137,
  nm: 'year-40M',
  ddd: 1,
  assets: [],
  layers: [
    {
      ddd: 1,
      ind: 1,
      ty: 4,
      nm: 'th1アウトライン',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 10,
              s: [0],
            },
            { t: 50, s: [100] },
          ],
          ix: 11,
        },
        rx: { a: 0, k: 0, ix: 8 },
        ry: { a: 0, k: 0, ix: 9 },
        rz: { a: 0, k: 0, ix: 10 },
        or: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 10,
              s: [0, 0, 0],
              to: [0, 0, 0],
              ti: [315, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 15,
              s: [0, 315, 0],
              to: [0, 0, 0],
              ti: [270, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 20,
              s: [0, 270, 0],
              to: [0, 0, 0],
              ti: [225, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 25,
              s: [0, 225, 0],
              to: [0, 0, 0],
              ti: [180, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 30,
              s: [0, 180, 0],
              to: [0, 0, 0],
              ti: [135, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 35,
              s: [0, 135, 0],
              to: [0, 0, 0],
              ti: [90, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 40,
              s: [0, 90, 0],
              to: [0, 0, 0],
              ti: [45, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 45,
              s: [0, 45, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 50, s: [0, 0, 0] },
          ],
          ix: 7,
        },
        p: { a: 0, k: [56.8, 67.8, 0], ix: 2 },
        a: { a: 0, k: [57, 68, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-56.46, 43.417],
                    [21.973, 43.417],
                    [21.973, 67.646],
                    [32.419, 67.646],
                    [32.419, 43.417],
                    [56.46, 43.417],
                    [56.46, 32.972],
                    [32.419, 32.972],
                    [32.419, -67.646],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'パス 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [21.973, -37.876],
                    [21.973, 32.972],
                    [-34.721, 32.972],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'パス 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'パスを結合 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.907622333601, 0.175959882549, 0.285074540681, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: '塗り 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [56.709, 67.896], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'トランスフォーム',
            },
          ],
          nm: 'グループ 1',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 10,
      op: 91,
      st: 25,
      bm: 0,
    },
    {
      ddd: 1,
      ind: 3,
      ty: 4,
      nm: 'th2アウトライン',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 20,
              s: [0],
            },
            { t: 60, s: [100] },
          ],
          ix: 11,
        },
        rx: { a: 0, k: 0, ix: 8 },
        ry: { a: 0, k: 0, ix: 9 },
        rz: { a: 0, k: 0, ix: 10 },
        or: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 20,
              s: [0, 0, 0],
              to: [0, 0, 0],
              ti: [315, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 25,
              s: [0, 315, 0],
              to: [0, 0, 0],
              ti: [270, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 30,
              s: [0, 270, 0],
              to: [0, 0, 0],
              ti: [225, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 35,
              s: [0, 225, 0],
              to: [0, 0, 0],
              ti: [180, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 40,
              s: [0, 180, 0],
              to: [0, 0, 0],
              ti: [135, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 45,
              s: [0, 135, 0],
              to: [0, 0, 0],
              ti: [90, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 50,
              s: [0, 90, 0],
              to: [0, 0, 0],
              ti: [45, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 55,
              s: [0, 45, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 60, s: [0, 0, 0] },
          ],
          ix: 7,
        },
        p: { a: 0, k: [163.6, 75.4, 0], ix: 2 },
        a: { a: 0, k: [62.5, 62.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 28.304],
                    [-28.304, 0],
                    [0, -28.304],
                    [28.304, 0],
                  ],
                  o: [
                    [0, -28.304],
                    [28.304, 0],
                    [0, 28.304],
                    [-28.304, 0],
                  ],
                  v: [
                    [-51.331, 0],
                    [0, -51.331],
                    [51.331, 0],
                    [0, 51.331],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'パス 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.694, 3.692],
                    [0, 0],
                    [-0.391, 0.984],
                    [-0.018, 0.045],
                    [-0.323, 0.953],
                    [-0.033, 0.098],
                    [-0.258, 0.912],
                    [-0.044, 0.157],
                    [-0.198, 0.867],
                    [-0.045, 0.214],
                    [-0.144, 0.827],
                    [-0.043, 0.265],
                    [-0.098, 0.797],
                    [-0.033, 0.301],
                    [-0.054, 0.809],
                    [-0.016, 0.296],
                    [0, 1.113],
                    [1.805, 5.804],
                    [0.782, 1.847],
                    [0.434, 0.9],
                    [2.372, 3.171],
                    [1.329, 1.463],
                    [11.843, 3.047],
                    [2.045, 0.312],
                    [2.106, 0.107],
                    [1.066, 0],
                    [1.054, -0.053],
                    [2.045, -0.313],
                    [2.902, -0.902],
                    [1.848, -0.782],
                    [1.751, -0.951],
                    [1.643, -1.11],
                    [3.776, -4.577],
                    [2.426, -6.623],
                    [0, -7.463],
                    [-0.059, -1.099],
                    [-0.02, -0.296],
                    [-0.085, -0.801],
                    [-0.036, -0.3],
                    [-0.127, -0.788],
                    [-0.047, -0.263],
                    [-0.177, -0.814],
                    [-0.049, -0.213],
                    [-0.234, -0.852],
                    [-0.044, -0.156],
                    [-0.298, -0.895],
                    [-0.033, -0.098],
                    [-0.368, -0.933],
                    [-0.018, -0.045],
                    [-0.439, -0.959],
                    [0, -0.001],
                    [-2.357, -3.258],
                    [0, 0],
                    [-2.563, -2.492],
                    [-0.014, -0.013],
                    [-0.85, -0.739],
                    [-0.082, -0.071],
                    [-0.863, -0.667],
                    [-0.059, -0.045],
                    [-3.101, -1.635],
                    [-0.202, -0.103],
                    [-0.828, -0.38],
                    [-0.261, -0.116],
                    [-0.836, -0.327],
                    [-0.242, -0.092],
                    [-2.253, -0.566],
                    [-0.273, -0.065],
                    [-0.85, -0.165],
                    [-0.388, -0.068],
                    [-0.771, -0.103],
                    [-0.413, -0.047],
                    [-0.811, -0.061],
                    [-0.37, -0.022],
                    [-1.191, 0],
                    [-1.175, 0.066],
                    [-0.369, 0.027],
                    [-0.801, 0.092],
                    [-0.41, 0.056],
                    [-0.763, 0.131],
                    [-0.385, 0.074],
                    [-0.838, 0.199],
                    [-0.272, 0.069],
                    [-2.148, 0.807],
                    [-0.24, 0.093],
                    [-0.818, 0.362],
                    [-0.261, 0.119],
                    [-0.807, 0.415],
                    [-0.2, 0.106],
                    [-2.76, 2.12],
                    [-0.059, 0.046],
                    [-0.825, 0.71],
                    [-0.083, 0.072],
                    [-0.807, 0.782],
                    [-0.016, 0.015],
                    [-2.101, 2.902],
                    [0, 0],
                  ],
                  o: [
                    [0.001, -0.001],
                    [0.44, -0.959],
                    [0.019, -0.045],
                    [0.368, -0.933],
                    [0.033, -0.099],
                    [0.298, -0.895],
                    [0.045, -0.156],
                    [0.234, -0.852],
                    [0.048, -0.213],
                    [0.177, -0.814],
                    [0.047, -0.263],
                    [0.127, -0.788],
                    [0.036, -0.3],
                    [0.085, -0.801],
                    [0.02, -0.296],
                    [0.058, -1.099],
                    [0, -6.397],
                    [-0.602, -1.934],
                    [-0.391, -0.924],
                    [-1.735, -3.601],
                    [-1.186, -1.586],
                    [-7.978, -8.776],
                    [-1.974, -0.508],
                    [-2.045, -0.313],
                    [-1.053, -0.053],
                    [-1.066, 0],
                    [-2.105, 0.107],
                    [-3.068, 0.468],
                    [-1.934, 0.602],
                    [-1.847, 0.781],
                    [-1.75, 0.951],
                    [-4.93, 3.331],
                    [-4.407, 5.339],
                    [-2.425, 6.623],
                    [0, 1.113],
                    [0.016, 0.296],
                    [0.055, 0.809],
                    [0.033, 0.301],
                    [0.098, 0.797],
                    [0.042, 0.265],
                    [0.144, 0.827],
                    [0.047, 0.214],
                    [0.198, 0.867],
                    [0.043, 0.157],
                    [0.258, 0.913],
                    [0.033, 0.097],
                    [0.324, 0.954],
                    [0.018, 0.045],
                    [0.392, 0.984],
                    [0.001, 0],
                    [1.695, 3.692],
                    [0, 0],
                    [2.101, 2.903],
                    [0.014, 0.014],
                    [0.808, 0.784],
                    [0.082, 0.073],
                    [0.826, 0.711],
                    [0.059, 0.046],
                    [2.761, 2.121],
                    [0.201, 0.106],
                    [0.807, 0.415],
                    [0.26, 0.119],
                    [0.819, 0.362],
                    [0.241, 0.093],
                    [2.148, 0.806],
                    [0.272, 0.069],
                    [0.837, 0.199],
                    [0.385, 0.074],
                    [0.761, 0.131],
                    [0.412, 0.056],
                    [0.802, 0.092],
                    [0.369, 0.027],
                    [1.175, 0.066],
                    [1.191, 0],
                    [0.371, -0.022],
                    [0.81, -0.061],
                    [0.413, -0.047],
                    [0.771, -0.103],
                    [0.386, -0.068],
                    [0.85, -0.165],
                    [0.273, -0.065],
                    [2.253, -0.566],
                    [0.242, -0.091],
                    [0.837, -0.327],
                    [0.263, -0.116],
                    [0.827, -0.38],
                    [0.202, -0.104],
                    [3.101, -1.635],
                    [0.06, -0.046],
                    [0.862, -0.667],
                    [0.083, -0.072],
                    [0.849, -0.739],
                    [0.014, -0.014],
                    [2.562, -2.492],
                    [0, 0],
                    [2.358, -3.258],
                  ],
                  v: [
                    [56.162, 25.728],
                    [56.163, 25.726],
                    [57.409, 22.811],
                    [57.463, 22.675],
                    [58.5, 19.847],
                    [58.6, 19.552],
                    [59.434, 16.842],
                    [59.567, 16.372],
                    [60.213, 13.791],
                    [60.359, 13.152],
                    [60.836, 10.689],
                    [60.978, 9.899],
                    [61.306, 7.519],
                    [61.42, 6.62],
                    [61.619, 4.203],
                    [61.686, 3.318],
                    [61.777, 0],
                    [59, -18.371],
                    [56.922, -24.046],
                    [55.685, -26.783],
                    [49.504, -36.962],
                    [45.729, -41.538],
                    [15.439, -59.832],
                    [9.408, -61.065],
                    [3.179, -61.697],
                    [0, -61.777],
                    [-3.18, -61.697],
                    [-9.408, -61.065],
                    [-18.371, -59],
                    [-24.047, -56.922],
                    [-29.447, -54.321],
                    [-34.54, -51.227],
                    [-47.67, -39.296],
                    [-58.029, -21.241],
                    [-61.777, 0],
                    [-61.686, 3.318],
                    [-61.619, 4.203],
                    [-61.42, 6.62],
                    [-61.306, 7.519],
                    [-60.977, 9.899],
                    [-60.836, 10.689],
                    [-60.36, 13.152],
                    [-60.213, 13.791],
                    [-59.567, 16.372],
                    [-59.434, 16.842],
                    [-58.6, 19.553],
                    [-58.501, 19.846],
                    [-57.463, 22.676],
                    [-57.41, 22.81],
                    [-56.164, 25.726],
                    [-56.163, 25.728],
                    [-50.066, 36.173],
                    [-50.065, 36.173],
                    [-43.057, 44.28],
                    [-43.014, 44.321],
                    [-40.526, 46.604],
                    [-40.279, 46.819],
                    [-37.747, 48.888],
                    [-37.571, 49.025],
                    [-28.765, 54.67],
                    [-28.159, 54.98],
                    [-25.707, 56.172],
                    [-24.925, 56.525],
                    [-22.441, 57.556],
                    [-21.721, 57.84],
                    [-15.119, 59.906],
                    [-14.296, 60.096],
                    [-11.767, 60.645],
                    [-10.606, 60.852],
                    [-8.307, 61.205],
                    [-7.072, 61.362],
                    [-4.653, 61.585],
                    [-3.548, 61.669],
                    [0, 61.777],
                    [3.547, 61.669],
                    [4.653, 61.585],
                    [7.072, 61.362],
                    [8.306, 61.205],
                    [10.607, 60.852],
                    [11.766, 60.645],
                    [14.296, 60.096],
                    [15.118, 59.906],
                    [21.721, 57.839],
                    [22.44, 57.556],
                    [24.924, 56.525],
                    [25.707, 56.172],
                    [28.158, 54.981],
                    [28.765, 54.67],
                    [37.57, 49.026],
                    [37.748, 48.887],
                    [40.277, 46.82],
                    [40.527, 46.604],
                    [43.012, 44.323],
                    [43.058, 44.279],
                    [50.065, 36.174],
                    [50.065, 36.173],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'パス 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'パスを結合 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.907622333601, 0.175959882549, 0.285074540681, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: '塗り 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [62.027, 62.027], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'トランスフォーム',
            },
          ],
          nm: 'グループ 1',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 20,
      op: 91,
      st: 20,
      bm: 0,
    },
    {
      ddd: 1,
      ind: 5,
      ty: 4,
      nm: 'th3アウトライン',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 30,
              s: [0],
            },
            { t: 70, s: [100] },
          ],
          ix: 11,
        },
        rx: { a: 0, k: 0, ix: 8 },
        ry: { a: 0, k: 0, ix: 9 },
        rz: { a: 0, k: 0, ix: 10 },
        or: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 30,
              s: [0, 0, 0],
              to: [0, 0, 0],
              ti: [315, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 35,
              s: [0, 315, 0],
              to: [0, 0, 0],
              ti: [270, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 40,
              s: [0, 270, 0],
              to: [0, 0, 0],
              ti: [225, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 45,
              s: [0, 225, 0],
              to: [0, 0, 0],
              ti: [180, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 50,
              s: [0, 180, 0],
              to: [0, 0, 0],
              ti: [135, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 55,
              s: [0, 135, 0],
              to: [0, 0, 0],
              ti: [90, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 60,
              s: [0, 90, 0],
              to: [0, 0, 0],
              ti: [45, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 65,
              s: [0, 45, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 70, s: [0, 0, 0] },
          ],
          ix: 7,
        },
        p: { a: 0, k: [231.4, 115.4, 0], ix: 2 },
        a: { a: 0, k: [10.5, 21, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [2.837, 0],
                    [0, 5.243],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-2.336, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [-5.734, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 3.563],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [3.371, 20.553],
                    [-4.844, 12.538],
                    [-4.844, -5.361],
                    [-9.757, -5.361],
                    [-9.757, -12.012],
                    [-4.844, -12.012],
                    [-4.844, -20.553],
                    [3.464, -20.553],
                    [3.464, -12.012],
                    [9.757, -12.012],
                    [9.757, -5.361],
                    [3.464, -5.361],
                    [3.464, 9.337],
                    [6.129, 13.824],
                    [8.542, 13.824],
                    [8.542, 20.02],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'パス 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.907622333601, 0.175959882549, 0.285074540681, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: '塗り 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [10.007, 20.802], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'トランスフォーム',
            },
          ],
          nm: 'グループ 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 30,
      op: 92,
      st: 55,
      bm: 0,
    },
    {
      ddd: 1,
      ind: 7,
      ty: 4,
      nm: 'th4アウトライン',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 40,
              s: [0],
            },
            { t: 80, s: [100] },
          ],
          ix: 11,
        },
        rx: { a: 0, k: 0, ix: 8 },
        ry: { a: 0, k: 0, ix: 9 },
        rz: { a: 0, k: 0, ix: 10 },
        or: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 40,
              s: [0, 0, 0],
              to: [0, 0, 0],
              ti: [315, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 45,
              s: [0, 315, 0],
              to: [0, 0, 0],
              ti: [270, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 50,
              s: [0, 270, 0],
              to: [0, 0, 0],
              ti: [225, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 55,
              s: [0, 225, 0],
              to: [0, 0, 0],
              ti: [180, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 60,
              s: [0, 180, 0],
              to: [0, 0, 0],
              ti: [135, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 65,
              s: [0, 135, 0],
              to: [0, 0, 0],
              ti: [90, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 70,
              s: [0, 90, 0],
              to: [0, 0, 0],
              ti: [45, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 75,
              s: [0, 45, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 80, s: [0, 0, 0] },
          ],
          ix: 7,
        },
        p: { a: 0, k: [258.4, 113.8, 0], ix: 2 },
        a: { a: 0, k: [14.5, 22.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [3.436, 0],
                    [1.31, -1.707],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-3.905, 0],
                    [0, -7.107],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, -4.076],
                    [-2.526, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.784, -1.549],
                    [7.178, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [5.782, 22.026],
                    [5.782, 1.615],
                    [0.745, -4.361],
                    [-5.771, -1.46],
                    [-5.728, 22.026],
                    [-14.2, 22.026],
                    [-14.2, -22.026],
                    [-5.728, -22.026],
                    [-5.728, -8.075],
                    [3.228, -11.288],
                    [14.2, -0.427],
                    [14.2, 22.026],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'パス 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.907622333601, 0.175959882549, 0.285074540681, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: '塗り 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [14.45, 22.276], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'トランスフォーム',
            },
          ],
          nm: 'グループ 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 40,
      op: 91,
      st: 62,
      bm: 0,
    },
  ],
  markers: [],
}
