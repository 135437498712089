import $ from 'jquery'
import { isTablet } from 'mobile-device-detect'

const setMetaViewport = () => {
  if (isTablet) {
    $('meta[name="viewport"]').attr('content', 'width=1240')
  }
}

export default setMetaViewport
