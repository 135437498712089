import scrollReveal from 'scrollreveal'

const reveal = () => {
  scrollReveal().reveal('.c-list-news > li:nth-child(n+20)', {
    duration: 800, // アニメーションの完了にかかる時間
    viewFactor: 0.2, // 0~1,どれくらい見えたら実行するか
  })
}

export default reveal
