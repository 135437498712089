import $ from 'jquery'
import Swiper from 'swiper/bundle'

const $slider = $('.js-image-slider')

const imageSlider = () => {
  if (!$slider.length) {
    return
  }

  $slider.each(function () {
    new Swiper($(this)[0], {
      loop: false,
      speed: 600,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: false,
      slidesPerView: 1,
      navigation: {
        nextEl: $(this).siblings('.swiper-button-next')[0],
        prevEl: $(this).siblings('.swiper-button-prev')[0],
      },
    })
  })
}

export default imageSlider
