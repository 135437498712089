import $ from 'jquery'

const $form = $('.aform-content')

const contactForm = () => {
  if (!$form.length) {
    return
  }

  const $select = $form.find('select')

  $select.on('change', function () {
    $(this).toggleClass('-is-empty', $(this).val() === '')
  })

  $select.trigger('change')
}

export default contactForm
