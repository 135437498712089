import $ from 'jquery'
import { gsap } from 'gsap'
import { CSSPlugin } from 'gsap/CSSPlugin'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const scrollFadeIn = () => {
  const $target = $('.js-scroll-fadein')

  if (!$target.length) {
    return
  }

  gsap.registerPlugin(ScrollTrigger, CSSPlugin)

  $target.each(function (i, el) {
    gsap.fromTo(
      el,
      { y: 20, opacity: 0 },
      {
        scrollTrigger: {
          trigger: el,
          start: 'top 75%',
        },
        y: 0,
        opacity: 1,
        duration: 0.6,
        ease: 'Power1.easeOut',
        onComplete: () => {
          $(el).removeAttr('style')
        },
      }
    )
  })
}

export default scrollFadeIn
