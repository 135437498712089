import $ from 'jquery'

export default class Screen {
  constructor() {
    this.isLocked = 'is-locked'
    this.ele = document.querySelector('html')
  }

  // Lock screen
  lock() {
    const top = $(window).scrollTop()

    if (!$(this.ele).hasClass(this.isLocked)) {
      $(this.ele)
        .addClass(this.isLocked)
        .css({ top: (top * -1) })
        .data('top', top)
    }
  }

  // Unlock screen
  unlock() {
    const top = $(this.ele).data('top') || 0

    if ($(this.ele).hasClass(this.isLocked)) {
      $(this.ele)
        .removeClass(this.isLocked)
        .css({ top: '' })
      $(window).scrollTop(top)
    }
  }

  // Check device
  get isPC() {
    const mq = window.matchMedia('screen and (min-width: 897px)')
    return mq.matches
  }

  // Check screen size
  size() {
    const mq = window.matchMedia('screen and (min-width: 897px)')
    $(mq).on('change', () => {
      $(window).trigger('breakpointChange')
    })
  }

  // Check touch device
  isTouchDevice() {
    if (('ontouchstart' in window)
       || (navigator.maxTouchPoints > 0)
       || (navigator.msMaxTouchPoints > 0)) {
      $('html').addClass('-is-touch')
    }
  }
}
