import $ from 'jquery'
import Lottie from 'lottie-web'
import logoData from '../data/logoData'

const $target = $('#logo-year')

const logoAnimation = () => {
  if (!$target.length) {
    return
  }

  const params = {
    container: $target[0],
    renderer: 'html',
    loop: false,
    autoplay: true,
    animationData: logoData,
  }

  const initLogo = () => {
    Lottie.loadAnimation(params)
  }

  initLogo()

  $(window).on('breakpointChange', () => {
    $target.html('')
    initLogo()
  })
}

export default logoAnimation
