import $ from 'jquery'
import { gsap } from 'gsap'
import { CSSPlugin } from 'gsap/CSSPlugin'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Screen from './screen'

const screen = new Screen()
const $circle = $('.js-top-service-circle')
const $content = $('.js-top-service-content')

const animateTopServicePc = () => {
  if (!$circle.length || !$content.length) {
    return
  }

  const $society = $circle.find('.circle.-society')
  const $consumers = $circle.find('.circle.-consumers')
  const $clients = $circle.find('.circle.-clients')

  const $step0 = $content.find('[data-animate-step="0"]')
  const $step1 = $content.find('[data-animate-step="1"]')
  const $step2 = $content.find('[data-animate-step="2"]')
  const $step3 = $content.find('[data-animate-step="3"]')

  gsap.registerPlugin(ScrollTrigger, CSSPlugin)

  const setupScrolltrigger = () => {
    ScrollTrigger.matchMedia({
      '(min-width: 897px)': function () {
        // Step 0
        const tl0 = gsap.timeline({
          scrollTrigger: {
            trigger: $step0[0],
            start: 'top 20%',
            end: `+=${$step1.offset().top - $step0.offset().top - 10}`,
            scrub: true,
          },
        })

        tl0.addLabel('society', 0).fromTo(
          $society[0],
          {
            x: 0,
            y: 0,
            width: 200,
            height: 200,
            backgroundColor: 'rgba(99, 99, 99, 0.1)',
          },
          {
            x: -38,
            backgroundColor: 'rgba(253, 44, 44, 0.7)',
            zIndex: 0,
            width: 220,
            height: 220,
            immediateRender: false,
          },
          0
        )

        tl0.fromTo(
          $consumers[0],
          { x: 0, y: 0, width: 200, height: 200 },
          {
            x: 118,
            y: 12,
            zIndex: 1,
            width: 160,
            height: 160,
            immediateRender: false,
          },
          0
        )

        tl0.fromTo(
          $clients[0],
          { x: 0, y: 0, width: 200, height: 200 },
          {
            x: -40,
            y: -94,
            zIndex: 1,
            width: 120,
            height: 120,
            immediateRender: false,
          },
          0
        )

        // To Step 1
        const tl1 = gsap.timeline({
          scrollTrigger: {
            trigger: $step2[0],
            start: 'top 60%',
            end: `+=${$step2.height() * 0.75}`,
            scrub: true,
          },
        })

        tl1.addLabel('consumers', 0).fromTo(
          $society[0],
          {
            x: -38,
            backgroundColor: 'rgba(253, 44, 44, 0.7)',
            zIndex: 0,
            width: 220,
            height: 220,
          },
          {
            x: 128,
            y: 131,
            zIndex: 1,
            width: 120,
            height: 120,
            backgroundColor: 'rgba(99, 99, 99, 0.1)',
            immediateRender: false,
          },
          0
        )

        tl1.fromTo(
          $consumers[0],
          {
            x: 118,
            y: 12,
            zIndex: 1,
            width: 160,
            height: 160,
            backgroundColor: 'rgba(99, 99, 99, 0.1)',
          },
          {
            x: 48,
            y: -124,
            zIndex: 0,
            width: 220,
            height: 220,
            backgroundColor: 'rgba(253, 44, 44, 0.7)',
            immediateRender: false,
          },
          0
        )

        tl1.fromTo(
          $clients[0],
          { x: -40, y: -94, zIndex: 1, width: 120, height: 120 },
          {
            x: -97,
            y: 13,
            zIndex: 1,
            width: 160,
            height: 160,
            immediateRender: false,
          },
          0
        )

        // To Step 2
        const tl2 = gsap.timeline({
          scrollTrigger: {
            trigger: $step3[0],
            start: 'top 60%',
            end: `+=${$step3.height() * 0.75}`,
            scrub: true,
          },
        })

        tl2.addLabel('clients', 0).fromTo(
          $society[0],
          {
            x: 128,
            y: 131,
            zIndex: 1,
            width: 120,
            height: 120,
          },
          {
            x: 31,
            y: 198,
            zIndex: 1,
            width: 160,
            height: 160,
            immediateRender: false,
          },
          0
        )

        tl2.fromTo(
          $consumers[0],
          {
            x: 48,
            y: -124,
            zIndex: 0,
            width: 220,
            height: 220,
            paddingLeft: 0,
            backgroundColor: 'rgba(253, 44, 44, 0.7)',
          },
          {
            x: 215,
            y: -94,
            zIndex: 1,
            width: 120,
            height: 120,
            paddingLeft: '70px',
            backgroundColor: 'rgba(99, 99, 99, 0.1)',
            immediateRender: false,
          },
          0
        )

        tl2.fromTo(
          $clients[0],
          {
            x: -97,
            y: 13,
            zIndex: 1,
            width: 160,
            height: 160,
            backgroundColor: 'rgba(99, 99, 99, 0.1)',
          },
          {
            x: -106,
            y: -125,
            zIndex: 0,
            width: 220,
            height: 220,
            backgroundColor: 'rgba(253, 44, 44, 0.7)',
            immediateRender: false,
          },
          0
        )
      },
    })
  }

  // reset and re-init animation when breakpoint change
  $(window).on('breakpointChange orientationchange', () => {
    if (!screen.isPC) {
      $society.removeAttr('style')
      $consumers.removeAttr('style')
      $clients.removeAttr('style')
    } else {
      ScrollTrigger.getAll().forEach(ST => {
        if ($(ST.trigger).data('animate-step')) {
          ST.kill()
        }
      })
      setupScrolltrigger()
    }
  })

  setupScrolltrigger()
}

export default animateTopServicePc
