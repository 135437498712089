import $ from 'jquery'
import Swiper from 'swiper/bundle'
import { isIOS, isTablet } from 'mobile-device-detect'

const $mv = $('#main-visual')

const mainVisual = () => {
  if (!$mv.length) {
    return
  }

  // fix 100vh issue on IOS by manually set height
  const setMvHeight = () => {
    const height = $(window).innerHeight()

    if (height > 500) {
      $mv.height(height)
    } else {
      $mv.height('')
    }
  }

  let oldHeight = 0

  if (isTablet) {
    // fixed MV cut off in tablet
    $mv.height(696)
  } else if (isIOS) {
    $(window).on('resize', () => {
      const newHeight = $(window).innerHeight()
      if (oldHeight !== newHeight) {
        setMvHeight()
        oldHeight = newHeight
      }
    })

    setMvHeight()
  }

  new Swiper($mv.find('.swiper-container')[0], {
    loop: false,
    speed: 800,
    effect: 'fade',
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    preventInteractionOnTransition: true,
    loopPreventsSlide: true,
    pagination: false,
    slidesPerView: 1,
    navigation: {
      nextEl: $mv.find('.btn-next')[0],
      prevEl: $mv.find('.btn-prev')[0],
    },
    breakpoints: {
      897: {
        speed: 1200,
      },
    },
    on: {
      beforeInit: () => {
        $mv.find('.swiper-slide').eq(0).addClass('-active-state')
      },
      slideChange: swiper => {
        $(swiper.slides)
          .filter('.swiper-slide-active')
          .addClass('-active-state')
      },
      slideChangeTransitionEnd: swiper => {
        $(swiper.slides)
          .not('.swiper-slide-active')
          .removeClass('-active-state')
      },
    },
  })
}

export default mainVisual
