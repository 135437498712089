import $ from 'jquery'
import Screen from './screen'

const screen = new Screen()
const $nav = $('.js-side-nav')
const TARGET_CLASS = '.js-side-panel'

const bottomNavBarSp = () => {
  if (!$nav.length) {
    return
  }

  if (!screen.isPC) {
    $nav.find(TARGET_CLASS).hide()
  }

  const $footer = $('#footer')
  let lastScroll = 0

  $(window)
    .on('scroll', () => {
      if (screen.isPC) {
        return
      }

      const scroll = window.pageYOffset || document.documentElement.scrollTop
      const footerOffset = $footer.offset().top

      if (
        scroll > lastScroll
      || $(window).scrollTop() + $(window).height() > footerOffset
      || $(window).scrollTop() + $(window).height() < $nav.offset().top
      ) {
        $nav.find(`${TARGET_CLASS}:not(:animated)`).stop().fadeOut(200)
      } else {
        $nav.find(`${TARGET_CLASS}:not(:animated)`).stop().fadeIn(200)
      }

      lastScroll = scroll < 0 ? 0 : scroll
    })
    .on('breakpointChange', () => {
      $nav.find(TARGET_CLASS).removeAttr('style')
    })
}

export default bottomNavBarSp
