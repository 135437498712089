import $ from 'jquery'

const $entryEm = $('.c-box-news-cms em')

const tagChange = () => {
  $entryEm.each((index, el) => {
    const $this = $(el)
    $this.replaceWith(() => {
      $this.replaceWith(`<p class="note">${$this.text()}</p>`)
    })
  })
}

export default tagChange
