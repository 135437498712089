import $ from 'jquery'
import Screen from './screen'

const screen = new Screen()
const $panel = $('.js-list-service-side')
const $content = $('.js-scroll-active-menu')

const initAccordion = () => {
  if (!$panel.length) {
    return
  }

  const PANEL_SUB_CLASS = '.c-list-service-panelsub'

  $panel.find('.-accordion').each(function () {
    $(this)
      .on('mouseenter', function () {
        if (!screen.isPC) {
          return
        }

        //$(this).children(PANEL_SUB_CLASS).stop().slideDown(300)
        $(this)
          .children(PANEL_SUB_CLASS)
          .stop()
          .animate({ 'height': 'toggle' })
          //.slideDown(300)
      })
      .on('mouseleave', function () {
        if (!screen.isPC) {
          return
        }

        $(this).children(PANEL_SUB_CLASS).stop().slideUp(300)
      })
  })

  $(window).on('breakpointChange', () => {
    $panel.find('.-accordion').removeAttr('style')
  })
}

const initSidebar = () => {
  const $sidebar = $('.side > .stick__space')
  const $main = $('div.main')
  const $height = $main.height() - $('.side > .stick__follow').height()
  //const $height = $main.height()
  $sidebar.css('height', `${$height}px`)
}

const initScrollActive = () => {
  if (!$panel.length || !$content.length) {
    return
  }

  const $section = $content.find('[id]')
  const ACTIVE_CLASS = '-is-active'

  $(window).on('scroll', function () {
    if (!screen.isPC) {
      return
    }

    const indicator = $(window).scrollTop() + ($('#header').height() * 1.5)

    const $filtered = $section.filter(
      (index, el) => indicator >= $(el).offset().top
    )

    if ($filtered.length) {
      const targetId = $filtered.eq(-1).attr('id')

      $panel
        .find('a[href^="#"]')
        .removeClass(ACTIVE_CLASS)
        .filter((index, el) => $(el).attr('href') === `#${targetId}`)
        .addClass(ACTIVE_CLASS)
    } else {
      $panel.find('a[href^="#"]').removeClass(ACTIVE_CLASS)
    }
  })
}

const initScrollSmallTitle = () => {
  const $side = $('.l-contents-side')

  if (!$side.length) {
    return
  }

  $(window).on('scroll', () => {
    $side.toggleClass('-is-scrolled', $(window).scrollTop() > 0)
  })
}

const navSidePanel = () => {
  initAccordion()
  initScrollActive()
  initScrollSmallTitle()
  initSidebar()
}

export default navSidePanel
