import $ from 'jquery'
import Screen from './screen'

const screen = new Screen()
const IS_OPEN = '-is-menu-open'
const $header = $('#header')
const $nav = $('#nav-global')
const $menu = $('#header-menu')

const headerMenu = () => {
  $menu.on('click', function () {
    if (screen.isPC) {
      return
    }

    if (!$header.hasClass(IS_OPEN)) {
      $header.addClass(IS_OPEN)
      $nav.stop().fadeIn(200)
      screen.lock()
    } else {
      $header.removeClass(IS_OPEN)
      $nav.stop().fadeOut(200, function () {
        $(this).removeAttr('style')
      })
      screen.unlock()
    }
  })

  $(window).on('breakpointChange', () => {
    if (screen.isPC && $header.hasClass(IS_OPEN)) {
      $header.removeClass(IS_OPEN)
      $nav.removeAttr('style')
      screen.unlock()
    }
  })
}

export default headerMenu
