import $ from 'jquery'

const $select = $('.js-custom-select')

const customSelectbox = () => {
  if (!$select.length) {
    return
  }

  $select.children('select').on('change', function () {
    const valueText = $(this).children('option:selected').text()
    $(this).siblings('.text').text(valueText)
  })

  $select.trigger('change')
}

export default customSelectbox
