import $ from 'jquery'
import 'jquery-match-height'

const $tab = $('.js-tab-button')
const ACTIVE_CLASS = '-is-active'

const tabContent = () => {
  if (!$tab.length) {
    return
  }

  $tab.each(function () {
    const $button = $(this).children()
    const $content = $(this).next('.js-tab-content')

    if (!$content.length) {
      return
    }

    const calcSidebar = () => {
      const $sidebar = $('.side > .stick__space')
      const $main = $('div.main')
      const $height = $main.height() - $('.side > .stick__follow').height()
      //const $height = $main.height() - 618
      $sidebar.css('height', `${$height}px`)
    }

    const setActiveTab = index => {
      $button
        .eq(index)
        .addClass(ACTIVE_CLASS) 
        .siblings()
        .removeClass(ACTIVE_CLASS)
      $content.children().eq(index).show().siblings()
        .hide()

      // refresh matchheight
      // eslint-disable-next-line no-underscore-dangle
      $.fn.matchHeight._update()
    }

    // click event
    $button.on('click', function () {
      if ($(this).hasClass(ACTIVE_CLASS)) {
        return
      }

      const index = $(this).index()
      setActiveTab(index)
      calcSidebar()
    })

    // check current when first loaded
    const currentIndex = $button.filter(`.${ACTIVE_CLASS}`).index()
    if (currentIndex > -1) {
      setActiveTab(currentIndex)
    } else {
      // active first tab
      setActiveTab(0)
    }
  })
}

export default tabContent
