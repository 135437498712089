import $ from 'jquery'
import Screen from './screen'

const screen = new Screen()
const $panel = $('.js-list-philosophy-side')
const $content = $('.js-scroll-active-menu')

const initScrollActive = () => {
  if (!$panel.length || !$content.length) {
    return
  }

  const $section = $content.find('[id]')
  const ACTIVE_CLASS = '-is-active'

  $(window).on('scroll', function () {
    if (!screen.isPC) {
      return
    }

    const indicator = $(window).scrollTop() + ($('#header').height() * 1.5)

    const $filtered = $section.filter(
      (index, el) => indicator >= $(el).offset().top
    )

    if ($filtered.length) {
      const targetId = $filtered.eq(-1).attr('id')

      $panel
        .find('a[href^="#"]')
        .removeClass(ACTIVE_CLASS)
        .filter((index, el) => $(el).attr('href') === `#${targetId}`)
        .addClass(ACTIVE_CLASS)
    } else {
      $panel.find('a[href^="#"]').removeClass(ACTIVE_CLASS)
    }
  })
}

const navPhilosophy = () => {
  initScrollActive()
}

export default navPhilosophy
