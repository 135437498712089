import $ from 'jquery'

const $listItem = $('.p-list-news .item')
const $detailHeader = $('.c-box-news-detail-heading')

const sessionCheck = () => {
  function dateToStr24HPad0(date, format) {
    if (!format) {
      // デフォルト値
      format = 'YYYYMMDD'
    }

    // フォーマット文字列内のキーワードを日付に置換する
    format = format.replace(/YYYY/g, date.getFullYear())
    format = format.replace(/MM/g, `0${date.getMonth() + 1}`.slice(-2))
    format = format.replace(/DD/g, `0${date.getDate()}`.slice(-2))

    return format
  }

  if ($listItem.length) {
    $listItem.each((index, el) => {
      const $this = $(el)
      const startDate = $this.data('start')
      const endDate = $this.data('end')
      const startDateText = String(startDate)

      const nowDate = dateToStr24HPad0(new Date())

      if (!endDate) {
        if (!startDateText === nowDate) {
          $this.find('.tag.-event').remove()
        }
      } else if (startDate > nowDate || endDate < nowDate) {
        $this.find('.tag.-event').remove()
      }
    })
  }

  if ($detailHeader.length) {
    $detailHeader.each((index, el) => {
      const $this = $(el)
      const startDate = $this.data('start')
      const endDate = $this.data('end')
      const startDateText = String(startDate)

      const nowDate = dateToStr24HPad0(new Date())

      if (!endDate) {
        if (!startDateText === nowDate) {
          $this.find('.tag.-red').remove()
        }
      } else if (startDate > nowDate) {
        $this.find('.tag.-red').remove()
      } else if (endDate < nowDate) {
        $this.find('.tag.-red').remove()
      }
    })
  }
}

export default sessionCheck
