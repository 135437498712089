import $ from 'jquery'
import Swiper from 'swiper/bundle'
import Screen from './screen'

const screen = new Screen()

const initScrollFixed = () => {
  const $target = $('.js-clients-scroll-fixed')

  if (!$target.length) {
    return
  }

  $target.each(function () {
    const $boxText = $(this).children('.boxtext')
    const $boxImage = $(this).children('.boximage')
    const $content = $boxText.children()
    const headerHeight = $('#header').height()

    $(window)
      // set content width
      .on('resize', () => {
        if (!screen.isPC) {
          return
        }
        $content.width($boxText.width())
      })

      // set fixed on scroll
      .on('scroll', () => {
        if (!screen.isPC) {
          return
        }

        const scrollTop = $(window).scrollTop() + headerHeight
        const startOffset = $boxImage.offset().top
        const endOffset = startOffset + $boxImage.height() - $content.height()

        if (
          $boxImage.height() > $content.height()
          && scrollTop > startOffset
          && scrollTop < endOffset
        ) {
          $content.css({
            position: 'fixed',
            top: headerHeight,
            bottom: 'auto',
            left: 'auto',
            width: $boxText.width(),
          })
        } else if (
          $boxImage.height() > $content.height()
          && scrollTop >= endOffset
        ) {
          $content.css({
            position: 'absolute',
            top: 'auto',
            bottom: 0,
            left: 0,
            width: $boxText.width(),
          })
        } else {
          $content.removeAttr('style')
        }
      })

      // reset style in SP
      .on('breakpointChange orientationchange', () => {
        if (screen.isPC) {
          return
        }
        $content.removeAttr('style')
      })
  })
}

const initSliderSp = () => {
  const $target = $('.js-clients-slider-sp')

  if (!$target.length) {
    return
  }

  const $slider = []
  const options = {
    loop: false,
    speed: 600,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    pagination: false,
    slidesPerView: 1,
  }

  const createSlider = () => {
    if (screen.isPC) {
      return
    }

    $target.each(function (i) {
      if (screen.isPC) {
        return
      }

      options.navigation = {
        nextEl: $(this).siblings('.btn-next')[0],
        prevEl: $(this).siblings('.btn-prev')[0],
      }

      $slider[i] = new Swiper($(this)[0], options)
    })
  }

  $(window).on('breakpointChange', () => {
    if (screen.isPC) {
      $slider.forEach(el => {
        if (typeof el.destroy === 'function') {
          el.destroy(true, true)
        }
      })
    } else {
      createSlider()
    }
  })

  createSlider()
}

const clientsPage = () => {
  initScrollFixed()
  initSliderSp()
}

export default clientsPage
