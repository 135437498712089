import $ from 'jquery'

const linkToHash = () => {
  const { hash } = window.location
  const headerHeight = $('#header').height()

  if (!hash && !$(hash).length) {
    return
  }

  window.history.replaceState(null, null, ' ')

  $(window).on('load', () => {
    let targetOffset = $(hash).offset().top - headerHeight

    // for the section with scrollFadeIn effect
    if (
      ($(hash).closest('.js-scroll-fadein').length
      && $(hash).closest('.js-scroll-fadein').css('opacity').toString() === '0')
      || ($(hash).hasClass('.js-scroll-fadein')
       && $(hash).css('opacity').toString() === '0')
    ) {
      targetOffset -= 20
    }

    $('html, body').animate(
      {
        scrollTop: targetOffset,
      },
      400
    )
  })
}

export default linkToHash
