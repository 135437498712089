import $ from 'jquery'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const PAGE = '#top'
const IS_FIXED = '-is-small'
const $header = $('#header')

const headerFixed = () => {
  if (!$('body').is(PAGE)) {
    return
  }

  $(window).on('scroll', function () {
    const scrollTop = $(this).scrollTop()

    $header.toggleClass(IS_FIXED, scrollTop > 0)
  })
}

const $wrap = $header.children('.drop-filter')
    
gsap.registerPlugin(ScrollTrigger)
  
// Part 1
gsap.to($wrap, {
  autoAlpha: 1,
  scrollTrigger: {
    trigger: '.filter-start',
    endTrigger: '#footer',
    start: 'top 90px',
    toggleClass: { targets: $wrap, className: 'is-active' },
    //markers: true,
  },
})

export default headerFixed
