import $ from 'jquery'

const $pageTop = $('#btn-pagetop')

const pageTop = () => {
  if (!$pageTop.length) {
    return
  }

  const $box = $pageTop.children()

  $box.children('.button').on('click', e => {
    e.preventDefault()
    $('html, body').animate(
      {
        scrollTop: 0,
      },
      400
    )
  })

  const setPosition = () => {
    $pageTop
      .removeAttr('style')
      .css({
        width: $box.width(),
        height: $box.height(),
      })
  }

  $(window)
    .on('scroll', () => {
      const scrollTop = $(window).scrollTop()
      const indicator = scrollTop
        + ($(window).height()
          - (parseInt($box.css('bottom'), 10) + $box.height()))
      const stopPos = $pageTop.offset().top

      $box.toggleClass('-is-stopped', indicator >= stopPos)

      if (scrollTop > 0) {
        $box.filter(':not(:animated)').stop().fadeIn(200)
      } else {
        $box.stop().fadeOut(200)
      }
    })
    .on('breakpointChange', setPosition)

  setPosition()
}

export default pageTop
