import $ from 'jquery'
import 'jquery-match-height'

const cmnMatchheight = () => {
  const $target = $('.js-matchheight-group')

  if (!$target.length) {
    return
  }

  $target.find('.js-matchheight-item').matchHeight({ property: 'min-height' })
}

const cardPublishing = () => {
  const $card = $('.c-card-publishing')

  if (!$card.length) {
    return
  }

  $card.find('.boximage').matchHeight({ property: 'min-height' })
  $card.find('.name').matchHeight({ property: 'min-height' })
  $card.find('.author').matchHeight({ property: 'min-height' })
  $card.find('.boxtext').matchHeight({ property: 'min-height' })
}

const initMatchHeight = () => {
  cmnMatchheight()
  cardPublishing()
}

export default initMatchHeight
