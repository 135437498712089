import $ from 'jquery'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const $circle = $('.js-service-circle-sp')
const ACTIVE_CLASS = '-is-active'

const animateTopServiceSp = () => {
  if (!$circle.length) {
    return
  }

  gsap.registerPlugin(ScrollTrigger)
  ScrollTrigger.matchMedia({
    '(max-width: 896px)': function () {
      $circle.each(function (i, e) {
        gsap.from(e, {
          scrollTrigger: {
            start: 'top 40%',
            trigger: e,
            onEnter() {
              $(e).addClass(ACTIVE_CLASS)
            },
          },
        })
      })
    },
  })
}

export default animateTopServiceSp
