import $ from 'jquery'

function languageLink() {
  const $html = $('html')
  const $langBtn = $('#btnlang')
  const lang = $html.attr('lang')

  if ($langBtn.length > 0) {
    const currentUrlPathname = window.location.pathname
    let newPath = ''

    if (lang === 'ja') {
      newPath = `/english${currentUrlPathname}`
    } else {
      newPath = currentUrlPathname.replace('/english', '')
    }

    $langBtn.attr('href', newPath)
  }
}

export default languageLink
