import $ from 'jquery'
import Odometer from 'odometer'

const initAnimateNumber = () => {
  const $yearNumber = $('.js-history-number')

  if (!$yearNumber.length) {
    return
  }

  const $yearFrom = $('#year-from')
  const $yearTo = $('#year-to')
  const $section = $('.js-history-content').children('[data-year]')

  const formDigit = new Odometer({
    el: $yearFrom[0],
    value: '0000',
    format: 'dddd',
    duration: 500,
  })

  const toDigit = new Odometer({
    el: $yearTo[0],
    value: '0000',
    format: 'dddd',
    duration: 500,
  })

  const getCurrentYear = () => {
    const scrollTop = $(window).scrollTop()
    const indicator = scrollTop + $yearNumber.position().top

    const $filtered = $section.filter(
      (index, el) => indicator >= $(el).offset().top
    )

    if ($filtered.length) {
      const targetYear = $filtered.eq(-1).data('year')
      toDigit.update(targetYear)
    } else {
      toDigit.update(2021)
    }
  }

  $(window)
    .on('scroll', getCurrentYear)
    .on('load', () => {
      $yearNumber.addClass('-is-visible')
      getCurrentYear()
      formDigit.update(1976)
    })
}

const initScrollToYear = () => {
  const $navs = $('.js-history-nav')

  if (!$navs.length) {
    return
  }

  $navs.find('[data-to-year]').on('click', function (e) {
    e.preventDefault()

    const year = $(this).data('to-year').toString()
    let posTop = 0

    const $target = $('.js-history-content').children(`[data-year-anchor="${year}"]`)

    if ($target.length) {
      const offsetTop = $target.offset().top
      const padding = parseInt($target.css('padding-top'), 10)

      posTop = offsetTop + padding - $('#header').height()
    }

    $('html, body').animate(
      {
        scrollTop: posTop,
      },
      400
    )
  })
}

const scrollHide = () => {
  const $yearNumber = $('.js-history-number')

  if (!$yearNumber.length) {
    return
  }

  const $section = $('.js-history-content').children('[data-year]').last()
  const HIDE_CLASS = '-is-hide'

  $(window).on('scroll', function () {
    const scorllTop = $(window).scrollTop() + $yearNumber.position().top
    const endOffset = $section.offset().top + $section.height()

    $yearNumber.toggleClass(HIDE_CLASS, scorllTop > endOffset)
  })
}

const historyPage = () => {
  initAnimateNumber()
  initScrollToYear()
  scrollHide()
}

export default historyPage
