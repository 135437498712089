import $ from 'jquery'
import { Loader } from '@googlemaps/js-api-loader'
import Screen from './screen'

const screen = new Screen()
const apiKey = 'AIzaSyDlRRlMYS12ILknKpk3C03OpsOTBk8FJzs'
const location = {
  lat: 35.66133314557068,
  lng: 139.7365669822776,
}

const mapStyle = [
  {
    featureType: 'administrative',
    elementType: 'all',
    stylers: [
      {
        saturation: '-100',
      },
    ],
  },
  {
    featureType: 'administrative.province',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: -2,
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: '0',
      },
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [
      {
        saturation: '-100',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'all',
    stylers: [
      {
        lightness: '30',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'all',
    stylers: [
      {
        lightness: '10',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [
      {
        saturation: -80,
      },
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        hue: '#ffff00',
      },
      {
        lightness: -25,
      },
      {
        saturation: -97,
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels',
    stylers: [
      {
        lightness: -25,
      },
      {
        saturation: -100,
      },
    ],
  },
]

const googleMap = () => {
  if (!$('.js-map-axis').length) {
    return
  }

  const loader = new Loader({
    apiKey,
    version: 'weekly',
    libraries: [],
  })

  loader
    .load()
    .then(google => {
      const map = new google.maps.Map(document.querySelector('.js-map-axis'), {
        center: location,
        zoom: 17,
        styles: mapStyle,
      })

      const image = {
        url: '/assets/image/common/ico_logo_axis.svg',
        size: new google.maps.Size(250, 130),
        origin: new google.maps.Point(0, 0),
        anchor: screen.isPC
          ? new google.maps.Point(90, 65)
          : new google.maps.Point(60, 65),
        scaledSize: screen.isPC
          ? new google.maps.Size(170, 88.4)
          : new google.maps.Size(125, 65),
      }
      new google.maps.Marker({
        position: location,
        map: map,
        icon: image,
      })
    })
    .catch(e => {
      console.log(e)
    })
}

export default googleMap
