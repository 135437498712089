import $ from 'jquery'

const $links = $('a[href^="#"]').filter(function () {
  return $(this)
    .attr('href')
    .match(/^#[a-zA-Z0-9_-]+/)
})

const anchorLinks = () => {
  if (!$links.length) {
    return
  }

  $links.on('click', function (e) {
    e.preventDefault()

    const id = $(this).attr('href')
    const targetPos = $(id).offset().top || 0
    const headerHeight = $('#header').height()
    let targetOffset = targetPos - headerHeight

    if (targetPos > 0) {
      // for the section with scrollFadeIn effect
      if (
        ($(id).closest('.js-scroll-fadein').length
        && $(id).closest('.js-scroll-fadein').css('opacity').toString() === '0')
        || ($(id).hasClass('.js-scroll-fadein') && $(id).css('opacity').toString() === '0')
      ) {
        targetOffset -= 20
      }

      $('html, body').animate(
        {
          scrollTop: targetOffset,
        },
        400
      )
    }
  })
}

export default anchorLinks
