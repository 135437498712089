import $ from 'jquery'
import Screen from './screen'

const screen = new Screen()
const $nav = $('#nav-global')
const NAVSUB_CLASS = 'c-nav-global-sub'
const NAVLINK_CLASS = 'navlink'
const SUBLINK_CLASS = 'sublink'
const OPEN_CLASS = '-is-open'
const ACTIVE_CLASS = '-is-active'

const initSubMenu = () => {
  // mouse enter
  $nav
    .find(`.${NAVSUB_CLASS}`)
    .prev(`.${NAVLINK_CLASS}`)
    .on('mouseenter', function () {
      if (!screen.isPC) {
        return
      }

      $(this).parent().css({ overflow: 'visible' }) // fixed nav sub visible when first loaded

      $(this)
        .css({ overflow: 'visible' })
        .addClass(OPEN_CLASS)
        .siblings(`.${NAVSUB_CLASS}`)
        .addClass(OPEN_CLASS)
    })

  // mouse leave
  $nav
    .find(`.${NAVSUB_CLASS}`)
    .closest('li')
    .on('mouseleave', function () {
      if (!screen.isPC) {
        return
      }

      $(this)
        .children(`.${NAVLINK_CLASS}`)
        .removeClass(OPEN_CLASS)
        .next(`.${NAVSUB_CLASS}`)
        .removeClass(OPEN_CLASS)
    })
}

const initSetCurrent = () => {
  const urls = window.location.pathname.match(/[^/]+/g) || []
  const segment = $('html').attr('lang') !== 'ja' ? 1 : 0

  if (urls.length) {
    if (urls[segment] === 'about') {
      $nav.find(`.${NAVLINK_CLASS}`).eq(0).addClass(ACTIVE_CLASS)

      // active sub menu
      if (urls[segment + 1]) {
        $nav
          .find(`.${SUBLINK_CLASS}`)
          .filter((index, el) => $(el).attr('href').indexOf(`/${urls[segment + 1]}`) > -1)
          .addClass(ACTIVE_CLASS)
      }
    } else {
      $nav
        .find(`.${NAVLINK_CLASS}`)
        .filter((index, el) => $(el).attr('href').indexOf(`/${urls[segment]}`) > -1)
        .addClass(ACTIVE_CLASS)
    }
  }
}

const headerNav = () => {
  initSubMenu()
  initSetCurrent()
}

export default headerNav
